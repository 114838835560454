<template>
  <!-- 结算管理 -->
  <div class="purchase-plan-list settlementManagementPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :search-form-item-arr="formItemArr" @resetForm="getDaterange">
      <el-form-item label="创建时间" label-width="120px">
        <el-date-picker
          v-model="formInline.daterange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          size="small"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDaterange"
        />
      </el-form-item>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="bulkPayment">
          批量付款
        </el-button>
        <el-button size="small" @click="derivedData">
          导出
        </el-button>
      </div>
      <Table :selection="true" :operation-button="operationButton" :item-data="itemData" :list-data="listData" :loading="loading" @getCheckData="getCheckData" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 打印的内容  采取定位   让他飘出地球 -->
    <FinalStatement :product-id="productId" class="finalStatementBox" />
    <SelectAccount v-if="dialogTableVisible" ref="selectAccount" :money="paymentObj.amount" :settle-bank-id="paymentObj.settleBankId" @getAccount="getAccount" />
    <el-dialog
      :visible.sync="dialogVisible"
      title="批量付款"
      width="48%"
    >
      <Table :item-data="paymentItemData" :is-border="true" :list-data="morePaymentArr" />
      <p class="payable-total-amount">
        应付总额：<span>{{ Math.abs(paymentObj.amount) | numberToCurrency }}</span>元
      </p>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="widen-button" size="small" @click="dialogVisible=false">取消</el-button>
          <el-button
            class="widen-button"
            size="small"
            type="primary"
            @click="openBankAccount"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import { agentSettleSettlePayment, agentSettleInfoQueryByPage, agentSettleGetSettleAbcPayUrl, agentSettleBatchSettlePayment } from '@/http/custApi/financialManage'
import SelectAccount from '@/components/SelectAccount.vue'
import FinalStatement from './children/finalStatement.vue'
import axios from 'axios'
import { BSERURL } from '@/config/index'
import { timestampToTime, roundUpToInteger } from '@/unit/index'
export default {
  components: {
    FormSearch,
    Table,
    Pagination,
    FinalStatement,
    SelectAccount
  },
  data() {
    return {
      dialogVisible: false,
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      formItemArr: [
        { type: 'input', label: '合作企业', value: 'supplierCmpName', width: '120' },
        { type: 'input', label: '代采商', value: 'fundsCmpName', width: '120' },
        { type: 'input', label: '合同编号', value: 'contractNo', width: '120' },
        { type: 'select', label: '状态', value: 'settleStatus', width: '120', pLabel: 'dictName', pValue: 'dictId', child: [] },
        { type: 'select', label: '结算账户', value: 'settleBankId', width: '120', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('settleBankName') }
      ],
      paymentItemData: [
        { label: '合同编号', prop: 'custContractNo' },
        { label: '合作企业', prop: 'supplierCmpName' },
        { label: '结算日期', prop: 'createTime' },
        { label: '应付差额(元)', prop: 'settleDiff', type: 'money' }
      ],
      itemData: [
        { label: '结算单号', prop: 'settleNo', width: 150 },
        { label: '采购合同编号', prop: 'custContractNo', width: 150 },
        { label: '合作企业', prop: 'supplierCmpName', width: 180 },
        { label: '代采商', prop: 'fundsCmpName', width: 180 },
        { label: '结算账户', prop: 'settleBankId', width: 180, child: this.$store.getters.getDictionaryItem('settleBankName') },
        // { label: '货款金额(元)', prop: 'settleAmount', width: 120, type: 'money' },
        // { label: '已付货款(元)', prop: 'paymentPaid', width: 120, type: 'money' },
        // { label: '本次支付代采费(元)', prop: 'agentCost', width: 140 },
        // { label: '未支付垫资货款(元)', prop: 'agentUnpayment', width: 180, type: 'money' },
        // { label: '结算总数量', prop: 'settleTotalAmount', width: 120 },
        { label: '结算总数量(吨)', prop: 'settleTotalWeight', width: 120 },
        { label: '结算总金额(元)', prop: 'totalSettlementAmount', width: 120 },
        { label: '结算差额(元)', prop: 'settleDiff', width: 120, type: 'money' },
        { label: '创建时间', prop: 'createTime', width: 140 },
        // { label: '结算日期', prop: 'settleDate', width: 120 },
        { label: '结算状态', prop: 'settleStatus', width: 80, child: this.$store.getters.getDictionaryItem('cust_settle_status') }
      ],
      listData: [],
      morePaymentArr: [],
      operationButton: [
        {
          childOne: [{ num: 4, val: 'settleStatus' }, { num: 5, val: 'settleStatus' }, { num: 6, val: 'settleStatus' }],
          bType: 'primary',
          label: '打印结算单',
          handleEvent: this.turnUpPrinting
        },
        { num: 7, val: 'settleStatus', bType: 'primary', label: '付款', handleEvent: this.paymentTable },
        { num: 2, val: 'settleStatus', bType: 'primary', label: '付款', handleEvent: this.paymentTable },
        { num: 4, val: 'settleStatus', bType: 'primary', label: '去开票', handleEvent: this.makeOutInvoice },
        { num: 1, val: 'settleStatus', bType: 'primary', label: '详情', handleEvent: this.viewInfoTable, negation: true }
      ],
      paymentObj: {},
      loading: false,
      total: 0,
      productId: '',
      dialogTableVisible: false
    }
  },
  created() {
    if (this.$route.query.settleStatus) this.formInline.settleStatus = this.$route.query.settleStatus
    // 去掉待结算这个状态
    const settleStatusArr = this.$store.getters.getDictionaryItem('cust_settle_status')
    settleStatusArr.forEach((item, index) => {
      if (item.dictName === '待结算') {
        settleStatusArr.splice(index, 1)
      }
    })
    this.formItemArr[3].child = [...settleStatusArr]
  },
  methods: {
    openBankAccount() {
      this.dialogTableVisible = true
      setTimeout(() => {
        this.$refs.selectAccount.dialogTableVisible = true
      }, 200)
    },
    // 批量付款
    bulkPayment() {
      if (this.morePaymentArr.length === 0) {
        this.$message.error('请至少选择一条结算状态为待付款的数据')
        return
      }
      let flag1 = false; let flag2 = false; let flag3 = false
      let amount = 0
      this.morePaymentArr.forEach(item => {
        amount += Number(item.settleDiff)
        if (item.settleStatus !== 2)flag1 = true
        if (this.morePaymentArr[0].fundsCmpCode !== item.fundsCmpCode)flag2 = true
        if (this.morePaymentArr[0].settleBankId !== item.settleBankId)flag3 = true
      })
      this.paymentObj = {
        amount: roundUpToInteger(amount),
        settleBankId: this.morePaymentArr[0].settleBankId
      }
      if (flag1) {
        this.$message.error('已选数据里存在无需付款的数据')
        return
      }
      if (flag2) {
        this.$message.error('请选择属于同一代采商的数据')
        return
      }
      if (flag3) {
        this.$message.error('请选择结算账户相同的数据')
        return
      }
      this.dialogVisible = true
    },
    // 获取多选的数据
    getCheckData(value) {
      this.morePaymentArr = [...value]
    },
    // 导出数据
    derivedData() {
      if (this.total === 0) {
        this.$message.error('没有可导出的数据')
        return
      }
      if (this.total > 5000) {
        this.$message.error('导出功能限制5000条，请筛选后再导出')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      axios
        .post(
          BSERURL + 'agentSettle/export',
          { ...this.formInline, operFlat: 1 },
          {
            responseType: 'blob', // 设置成文件流
            headers: {
              token: sessionStorage.getItem(
                `${
                  sessionStorage.getItem('systemPlatformRoles') === '03'
                    ? 'tokenCust'
                    : 'tokenAgent'
                }`
              )
            }
          }
        )
        .then((res) => {
          loading.close()
          if (res.data && res.data.type === 'application/json') {
            this.$message.error('导出失败')
            return
          }
          const link = document.createElement('a')
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          }) // 文件流处理
          link.style.display = 'none' // 去除a标签的样式
          link.href = URL.createObjectURL(blob)
          link.download = `${this.$getUserInfoParameter('orgName')}_结算单数据_${timestampToTime(new Date(), true)}`
          document.body.appendChild(link)
          // 模拟点击事件
          link.click()
          // 移除创建的a标签
          window.URL.revokeObjectURL(link.href)
          document.body.removeChild(link)
        }).catch(() => {
          loading.close()
        })
    },
    // 获取时间
    getDaterange(e) {
      this.$forceUpdate()
      if (!e || e.length !== 2) {
        this.formInline.daterange = []
        this.formInline.settleDateStart = ''
        this.formInline.settleDateEnd = ''
        return
      }
      this.formInline.settleDateStart = e[0]
      this.formInline.settleDateEnd = e[1]
    },
    // 调起打印
    turnUpPrinting({ id }) {
      this.productId = id + '&&' + new Date().valueOf()
    },
    // 获取列表
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      agentSettleInfoQueryByPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 详情
    viewInfoTable(row) {
      this.$router.push({ path: '/financialManage/custBillingDetails', query: { settleAmount: row.settleAmount, settleDiff: row.settleDiff, id: row.id }})
    },
    // 开票
    makeOutInvoice({ id, settleAmount, custContractNo }) {
      this.$router.push({ path: '/financialManage/custMakeOutInvoice', query: { id, settleAmount, custContractNo }})
    },
    // 点击确定按钮
    getAccount(row) {
      this.paymentObj.settleBankId = row.settleBankId
      // 如果没有 settleId 就是批量支付的时候  有就是走的单个支付
      if (!this.paymentObj.settleId) {
        this.morePaymentArr.forEach(item => {
          item.paymentType = 1
          item.amount = item.settleDiff
          item.settleId = item.id
          item.operFlat = 1
        })
        agentSettleBatchSettlePayment(this.morePaymentArr, res => {
          this.$refs.selectAccount.dialogTableVisible = false
          setTimeout(() => {
            this.dialogTableVisible = false
          }, 100)
          this.dialogVisible = false
          this.$message.success('成功')
          if (row.settleBankId === 'ABC') window.open(res.data, '_blank')
          this.getdata()
        })
        return
      }
      if (row.settleBankId === 'ABC') {
        agentSettleGetSettleAbcPayUrl(this.paymentObj, res => {
          this.$refs.selectAccount.dialogTableVisible = false
          setTimeout(() => {
            this.dialogTableVisible = false
          }, 100)
          window.open(res.data, '_blank')
        })
        return
      }
      agentSettleSettlePayment(this.paymentObj, (res) => {
        this.$message.success('成功')
        this.getdata(true)
        this.$refs.selectAccount.dialogTableVisible = false
        setTimeout(() => {
          this.dialogTableVisible = false
        }, 100)
      })
    },
    // 付款
    async paymentTable(row) {
      this.paymentObj = {
        money: Math.abs(row.settleDiff),
        amount: Math.abs(row.settleDiff),
        paymentType: 1,
        settleId: row.id,
        settleStatus: row.settleStatus,
        settleBankId: row.settleBankId
      }
      // 如果之前有银行卡就不要去选银行卡了
      // if (row.settleBankId) {
      //   const confirm = await this.$confirm(`确认是否支付金额${this.paymentObj.money}元？`, '提示', { type: 'warning' }).catch(err => err)
      //   if (confirm !== 'confirm') return
      //   this.getAccount(row)
      //   return
      // }
      this.dialogTableVisible = true
      setTimeout(() => {
        this.$refs.selectAccount.dialogTableVisible = true
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
.settlementManagementPageBox{
  .payable-total-amount{
    padding-top: 16px;
    text-align: right;
    color: #333;
    font-size: 14px;
    span{
      color: $assistColor5;
      font-size: 26px;
      // font-weight: 800;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: center;
  }
  .finalStatementBox {
    position: absolute;
    top: -5000px;
    left: -5000px;
  }
}

</style>
